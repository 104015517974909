import * as _ from '@technically/lodash'

import { withProps } from 'recompose'

import {
  createControlTree,
  Text,
  Select,
} from '../../../platform/client/control-tree'

import { getSkuLabel, getSku } from '../common/meta'
import {
  PROP_DEF_DICT,
  FILL_AREA_DICT,
  CURRENCY_DICT,
  GLOVE_ASSET_DICT,
  SPORT_DICT,
  GLOVE_SIZE_DICT,
  PRICING_SCHEME_DICT,
  LEATHER_COLOR_DICT,
  TRIM_DICT,
  HEEL_PAD_DICT,
  LEATHERS,
  POSITIONS,
  LEATHER_COLORS,
  GLOVES,
  SPORTS,
  GLOVE_SIZES,
  FITS,
  HANDS,
  WEBS,
  PATCH_COLORS,
  SHELL_BACK_DESIGNS,
  SHELL_BACK_MATERIALS,
  LACE_LENGTHS,
  TOGGLES,
  TRIMS,
  STAMP_COLORS,
  FINGER_COLORS,
  FINGERS,
  PALM_PADS,
  HEEL_PADS,
  WRIST_LININGS,
  BREAK_INS,
  FONTS,
  LOGOS,
  FLAGS,
  VENDOR_DICT,
  GLOVE_MODEL_DICT,
} from '../common/sheets'
import { LOGO_CATEGORIES } from '../common/sheets/_logoCategories'

const TEXT_PATTERN = /^[a-zA-Z0-9 #@:\-/!$ÁÉÍÓÚáéíóú]+$/

const HSW_EXTRA_PRICE = 50

const gloveHasLeather = (leatherId) => (glove) =>
  glove.limitations.leatherId[leatherId]

const gloveHasFit = (fitId) => (glove) => glove.limitations.fitIds[fitId]

const gloveHasPosition = (positionId) => (glove) =>
  glove.limitations.positionIds[positionId]

const gloveHasSize = (sizeId) => (glove) => glove.props.sizeId === sizeId

const isLeatherAvailable = (gloves) => (leather) =>
  _.some(gloves, gloveHasLeather(leather.id))

const isFitAvailable = (gloves) => (fit) => _.some(gloves, gloveHasFit(fit.id))

const isPositionAvailable = (gloves) => (position) =>
  _.some(gloves, gloveHasPosition(position.id))

const isSizeAvailable = (gloves) => (gloveSize) =>
  _.some(gloves, gloveHasSize(gloveSize.id))

const isWebAvailableForGlove = (glove) => (web) =>
  glove.limitations.webIds[web.id]

const getLeatherColorSubset = (fillAreaId) => (leather) => {
  if (!leather) {
    return []
  }
  const { valuesDependOnLeathers, colorSetId } =
    FILL_AREA_DICT[fillAreaId].props
  const subsetId = valuesDependOnLeathers ? leather.id : 'common'
  return _.filter(
    LEATHER_COLORS,
    (leatherColor) => leatherColor.subsets[subsetId][colorSetId],
  )
}

// CFGG-76, CFGG-220
const isLiningUsingBackPalmColor = (leather, glove, gloveAsset) => {
  if (!leather || !glove || !gloveAsset) {
    return false
  }
  if (leather.id === 'hoh') {
    return gloveAsset.props.useBackPalmColorForLiningOnHOH
  }
  if (leather.id === 'kip') {
    return gloveAsset.props.useBackPalmColorForLiningOnKIP
  }
  return false
}

// CFGG-251
const isLiningUsingShellPalmColor = (gloveAsset, leather) =>
  gloveAsset.props.allowShellPalmColorForLining &&
  _.includes(['kip', 'hoh', 'rev1x'], leather.id)

const isGenericWeltingAvailable = (glove, gloveAsset) =>
  !!glove &&
  !glove.asset.useStitchingInsteadOfWelting &&
  !!gloveAsset &&
  gloveAsset.props.welting

// Numbers are rendered on ovals, see getLayerInstructions
const isNumberAvailable = (gloveAsset) =>
  !!gloveAsset &&
  (gloveAsset.props.deco3D.web.Oval ||
    gloveAsset.props.deco3D.palm.Oval ||
    gloveAsset.props.deco3D.back.Oval)

const isThumbAvailable = (leather) => !!leather && leather.id !== 'rev1x'

const isPinkyAvailable = (leather) =>
  !!leather && !_.includes(['gxle', 'rla', 'rev1x'], leather.id) // CFGG-298

const boilerplate = (propDef) => {
  const tileImageTemplate =
    propDef.tileImageProps && _.template(propDef.tileImageProps)

  return {
    description: propDef.description,
    defaultValue:
      'defaultValueId' in propDef && propDef.defaultValueId ?
        propDef.defaultValueId
      : null,
    isRequired: !propDef.isOptional,
    extraCost: propDef.extraCost,
    tileType: propDef.tileType,
    viewName: propDef.viewNames[0],
    tileImageTemplate: tileImageTemplate ? () => tileImageTemplate : null,
    isAlwaysVisible: propDef.isAlwaysVisible ?? false,
  }
}

const controlTree = createControlTree({
  env: {
    currency: Text({
      isPrivate: true,
      value: () => () => CURRENCY_DICT[window.serverConfig?.currency ?? 'USD'],
    }),

    vendorId: Text({
      isPrivate: true,
      value: () => () => window.serverConfig?.vendorId,
    }),

    vendor: Text({
      isPrivate: true,
      value: () => () => VENDOR_DICT[window.serverConfig?.hostname ?? ''],
    }),
  },

  calc: {
    gloveAsset: Text({
      isPrivate: true,
      dependencies: ['product.glove', 'colors.welting.handSewn'],
      value: (glove, handSewn) => () => {
        if (!glove) {
          // Forces error when glove is not selected. Renderer won't get called when control tree is in invalid state.
          return undefined
        }
        return GLOVE_ASSET_DICT[
          handSewn?.id === 'yes' ?
            glove.asset.hswGloveAssetId
          : glove.asset.gloveAssetId
        ]
      },
    }),

    gloveModel: Text({
      isPrivate: true,
      dependencies: ['product.glove'],
      value: (glove) => () => {
        if (!glove) {
          // Forces error when glove is not selected. Renderer won't get called when control tree is in invalid state.
          return undefined
        }

        return GLOVE_MODEL_DICT[glove.modelId]
      },
    }),

    sport: Text({
      isPrivate: true,
      dependencies: ['product.glove'],
      isAvailable: (glove) => !!glove,
      value: (glove) => () => SPORT_DICT[glove.limitations.sportId],
    }),

    position: Text({
      isPrivate: true,
      dependencies: ['filter.position', 'product.glove'],
      isAvailable: (_position, glove) => !!glove,
      value: (position, glove) => () =>
        position ?
          [position]
        : _.filter(POSITIONS, ({ id }) => gloveHasPosition(id)(glove)),
    }),

    size: Text({
      isPrivate: true,
      dependencies: ['product.glove'],
      isAvailable: (glove) => !!glove,
      value: (glove) => () => GLOVE_SIZE_DICT[glove.props.sizeId],
    }),

    sku: Text({
      // This SKU is used for finding leather/glove combo.
      isPrivate: true,
      dependencies: ['product.leather', 'product.glove'],
      isAvailable: (leather, glove) => !!leather && !!glove,
      value: (leather, glove) => () => getSku(leather, glove),
    }),

    skuLabel: Text({
      // This SKU label is used in UI & renderer.
      isPrivate: true,
      dependencies: [
        'product.leather',
        'product.glove',
        'colors.shellBack.design',
      ],
      isAvailable: (leather, glove) => !!leather && !!glove,
      value: (leather, glove, shellBackDesign) => () =>
        getSkuLabel(leather, glove, shellBackDesign),
    }),

    price: Text({
      isPrivate: true,
      dependencies: [
        'product.leather',
        'product.glove',
        'env.currency',
        'colors.welting.handSewn',
      ],
      isAvailable: (leather, glove) => !!leather && !!glove,
      value: (leather, glove, currency, handSewn) => () => {
        const basePrice =
          PRICING_SCHEME_DICT[glove.props.pricingSchemeId].props.price[
            currency.id
          ][leather.id]

        const isHsw = !!handSewn && handSewn.id === 'yes'

        // HSW gloves have increase in any of available currencies.
        return isHsw ? basePrice + HSW_EXTRA_PRICE : basePrice
      },
    }),

    priceWithCurrency: Text({
      isPrivate: true,
      dependencies: ['calc.price', 'env.currency'],
      isAvailable: (price) => !!price,
      value: (price, currency) => () =>
        `${currency.props.prefix} ${price.toFixed(2)}`,
    }),
  },

  filtered: {
    gloves: Text({
      isPrivate: true,
      dependencies: ['env.vendor'],
      value: (vendor) => () =>
        GLOVES.filter((glove) => {
          let isHidden = glove.props.isHidden
          // Temporary fix to not show gloves that are not prepared for 2D
          if (!vendor.features.is3d) {
            isHidden = [
              'MT27CFB',
              '3029CFB',
              '204CFB',
              '205CFB',
              '206CFB',
            ].includes(glove.id)
          }
          return !isHidden
        }).map((glove) => ({
          ...glove,
          name: glove.id,
        })),
    }),

    glovesBySport: Text({
      isPrivate: true,
      dependencies: ['filtered.gloves', 'filter.sport'],
      value: (gloves, sport) => () =>
        sport == null ? gloves : (
          _.filter(gloves, (glove) => glove.limitations.sportId === sport.id)
        ),
    }),

    glovesByLeather: Text({
      isPrivate: true,
      dependencies: ['filtered.glovesBySport', 'product.leather'],
      value: (gloves, leather) => () =>
        leather == null ? gloves : (
          _.filter(gloves, (glove) => glove.limitations.leatherId[leather.id])
        ),
    }),

    glovesByFit: Text({
      isPrivate: true,
      dependencies: ['filtered.glovesByLeather', 'product.fit'],
      value: (gloves, fit) => () =>
        fit == null ? gloves : (
          _.filter(gloves, (glove) => glove.limitations.fitIds[fit.id])
        ),
    }),

    glovesByPosition: Text({
      isPrivate: true,
      dependencies: ['filtered.glovesByFit', 'filter.position'],
      value: (gloves, position) => () =>
        position == null ? gloves : (
          _.filter(
            gloves,
            (glove) => glove.limitations.positionIds[position.id],
          )
        ),
    }),

    glovesBySize: Text({
      isPrivate: true,
      dependencies: ['filtered.glovesByPosition', 'filter.size'],
      value: (gloves, size) => () =>
        size == null ? gloves : (
          _.filter(gloves, (glove) => glove.props.sizeId === size.id)
        ),
    }),
  },

  filter: {
    sport: Select({
      ...boilerplate(PROP_DEF_DICT.filter_sport),
      options: SPORTS,
    }),

    position: Select({
      ...boilerplate(PROP_DEF_DICT.filter_position),
      dependencies: ['filtered.glovesByFit', 'env.vendor'],
      options: POSITIONS,
      visibleOptions: (gloves, vendor) => {
        let positions = _.filter(POSITIONS, isPositionAvailable(gloves))

        if (vendor.features.disableTraining) {
          positions = _.reject(positions, { id: 'training' })
        }

        return positions
      },
    }),

    size: Select({
      ...boilerplate(PROP_DEF_DICT.filter_size),
      dependencies: ['filtered.glovesByPosition'],
      visibleOptions: (gloves) =>
        _.filter(GLOVE_SIZES, isSizeAvailable(gloves)),
      options: GLOVE_SIZES,
    }),
  },

  product: {
    leather: Select({
      ...boilerplate(PROP_DEF_DICT.product_leather),
      dependencies: ['filtered.glovesBySport', 'env.vendor'],
      options: LEATHERS,
      visibleOptions: (gloves, vendor) => {
        let leathers = _.filter(LEATHERS, isLeatherAvailable(gloves))

        if (vendor.features.disableGxle) {
          leathers = _.reject(leathers, { id: 'gxle' })
        }

        if (vendor.features.disableRev1x) {
          leathers = _.reject(leathers, { id: 'rev1x' })
        }

        return leathers
      },
    }),

    fit: Select({
      ...boilerplate(PROP_DEF_DICT.product_fit),
      dependencies: ['product.leather', 'filtered.glovesByLeather'],
      isAvailable: (leather, gloves) => !!leather && !!gloves.length,
      options: FITS,
      visibleOptions: (leather, gloves) =>
        _.filter(
          FITS,
          (fit) =>
            fit.subsets.leatherId[leather.id] && isFitAvailable(gloves)(fit),
        ),
    }),

    glove: Select({
      ...boilerplate(PROP_DEF_DICT.product_glove),
      dependencies: ['filtered.glovesBySize'],
      visibleOptions: (gloves) => gloves,
      options: GLOVES,
    }),

    throwingHand: Select({
      ...boilerplate(PROP_DEF_DICT.product_throwingHand),
      dependencies: ['product.glove'],
      isAvailable: (glove) => !!glove,
      visibleOptions: (glove) =>
        _.filter(HANDS, (hand) => glove.limitations.throwingHandIds[hand.id]),
      options: HANDS,
    }),
  },

  colors: {
    web: {
      style: Select({
        ...boilerplate(PROP_DEF_DICT.colors_web_style),
        dependencies: ['product.glove'],
        isAvailable: (glove) => !!glove,
        autoUnavailable: true,
        visibleOptions: (glove) =>
          _.filter(WEBS, isWebAvailableForGlove(glove)),
        options: WEBS,
        defaultValue: (glove) => glove.defaults?.webId,
      }),

      color: Select({
        ...boilerplate(PROP_DEF_DICT.colors_web_color),
        dependencies: ['product.leather', 'colors.web.style'],
        isAvailable: (leather, web) =>
          !!leather && !!web && web.id !== 'Trapeze',
        visibleOptions: getLeatherColorSubset('web'),
        options: LEATHER_COLORS,
      }),
    },

    logoPatch: Select({
      ...boilerplate(PROP_DEF_DICT.colors_logoPatch),
      dependencies: ['product.leather'],
      isAvailable: (leather) => !!leather && leather.limitations.hasPatches,
      visibleOptions: (leather) =>
        _.filter(
          PATCH_COLORS,
          (patchColor) => patchColor.subsets.leatherId[leather.id],
        ),
      options: PATCH_COLORS,
    }),

    shellBack: {
      design: Select({
        ...boilerplate(PROP_DEF_DICT.colors_shellBack_design),
        dependencies: ['product.leather', 'product.glove'],
        isAvailable: (leather, glove) => !!leather && !!glove,
        visibleOptions: (leather, glove) =>
          _.filter(
            SHELL_BACK_DESIGNS,
            (design) =>
              design.subsets.leatherId[leather.id] &&
              glove.limitations.shellBackDesignIds[design.id],
          ),
        options: SHELL_BACK_DESIGNS,
      }),

      material: Select({
        ...boilerplate(PROP_DEF_DICT.colors_shellBack_material),
        dependencies: [
          'colors.shellBack.design',
          'product.glove',
          'product.throwingHand',
        ],
        isAvailable: (design) => !!design,
        autoUnavailable: true,
        visibleOptions: (design, glove, throwingHand) =>
          _.filter(
            SHELL_BACK_MATERIALS,
            (material) =>
              material.subsets.shellBackDesignId[design.id] &&
              (!glove.limitations.shellBackMaterialHands ||
                [null, undefined, throwingHand.id].includes(
                  glove.limitations.shellBackMaterialHands[material.id],
                )),
          ),
        options: SHELL_BACK_MATERIALS,
      }),

      leatherColor: Select({
        ...boilerplate(PROP_DEF_DICT.colors_shellBack_leatherColor),
        dependencies: ['product.leather'],
        isAvailable: (leather) => leather?.limitations.hasLeatherOnBack,
        visibleOptions: getLeatherColorSubset('shellBack'),
        options: LEATHER_COLORS,
      }),
    },

    shellPalm: Select({
      ...boilerplate(PROP_DEF_DICT.colors_shellPalm),
      dependencies: ['product.leather'],
      isAvailable: (leather) => !!leather,
      visibleOptions: getLeatherColorSubset('shellPalm'),
      options: LEATHER_COLORS,
    }),

    backPalm: Select({
      ...boilerplate(PROP_DEF_DICT.colors_backPalm),
      dependencies: ['product.leather', 'calc.gloveAsset'],
      isAvailable: (leather, gloveAsset) =>
        !!leather && !!gloveAsset && gloveAsset.props.hasBackPalm,
      visibleOptions: getLeatherColorSubset('backPalm'),
      options: LEATHER_COLORS,
    }),

    laces: {
      fingerWeb: Select({
        ...boilerplate(PROP_DEF_DICT.colors_laces_fingerWeb),
        dependencies: ['product.leather'],
        isAvailable: (leather) => !!leather,
        visibleOptions: getLeatherColorSubset('laces'),
        options: LEATHER_COLORS,
      }),

      heel: Select({
        ...boilerplate(PROP_DEF_DICT.colors_laces_heel),
        dependencies: ['product.leather'],
        isAvailable: (leather) => !!leather,
        visibleOptions: getLeatherColorSubset('laces'),
        options: LEATHER_COLORS,
      }),

      laceLength: Select({
        ...boilerplate(PROP_DEF_DICT.colors_laces_laceLength),
        options: LACE_LENGTHS,
      }),
    },

    lining: Select({
      ...boilerplate(PROP_DEF_DICT.colors_lining),
      dependencies: [
        'product.leather',
        'product.glove',
        'colors.backPalm',
        'colors.shellPalm',
        'calc.gloveAsset',
      ],
      isAvailable: (
        _leather,
        _glove,
        _backPalmColor,
        _shellPalmColor,
        gloveAsset,
      ) => {
        if (!gloveAsset) {
          return false
        }

        return (
          gloveAsset.props.liningOnBackView || gloveAsset.props.liningOnPalmView
        )
      },
      visibleOptions: (
        leather,
        glove,
        backPalmColor,
        shellPalmColor,
        gloveAsset,
      ) => {
        if (!leather) {
          return []
        }

        if (isLiningUsingBackPalmColor(leather, glove, gloveAsset)) {
          // When using backPalm color, return only single color to hide the navigation item.
          return [LEATHER_COLOR_DICT[backPalmColor.id]]
        }

        let liningColors = getLeatherColorSubset('lining')(leather)

        if (isLiningUsingShellPalmColor(gloveAsset, leather)) {
          const id = 'shellPalmColor'
          const name = 'Shell Palm Color'

          liningColors = [
            ...(gloveAsset.props.isLiningShellPalmColorOnly ?
              []
            : liningColors),
            {
              ...shellPalmColor,
              id,
              name,
              props: {
                ...shellPalmColor.props,
                tileText: name,
              },
            },
          ]
        }

        return liningColors
      },
      options: LEATHER_COLORS,
      defaultValue: (
        leather,
        glove,
        backPalmColor,
        _shellPalmColor,
        gloveAsset,
      ) => {
        if (!leather) {
          return null
        }

        if (isLiningUsingBackPalmColor(leather, glove, gloveAsset)) {
          return backPalmColor.id
        }

        return PROP_DEF_DICT.colors_lining.defaultValueId
      },
    }),

    welting: {
      all: Select({
        ...boilerplate(PROP_DEF_DICT.colors_welting_all),
        dependencies: ['product.leather', 'product.glove', 'calc.gloveAsset'],
        isAvailable: (leather, glove, gloveAsset) =>
          isGenericWeltingAvailable(glove, gloveAsset) &&
          !!leather &&
          leather.limitations.weltingTypes.all,
        visibleOptions: getLeatherColorSubset('welting'),
        options: LEATHER_COLORS,
      }),

      palm: Select({
        ...boilerplate(PROP_DEF_DICT.colors_welting_palm),
        dependencies: ['product.leather', 'product.glove', 'calc.gloveAsset'],
        isAvailable: (leather, glove, gloveAsset) =>
          isGenericWeltingAvailable(glove, gloveAsset) &&
          !!leather &&
          leather.limitations.weltingTypes.palm &&
          !glove.limitations.isWeltingPalmDisabled,
        visibleOptions: getLeatherColorSubset('welting'),
        options: LEATHER_COLORS,
      }),

      back: Select({
        ...boilerplate(PROP_DEF_DICT.colors_welting_back),
        dependencies: [
          'product.leather',
          'product.glove',
          'calc.gloveAsset',
          'calc.gloveModel',
        ],
        isAvailable: (leather, glove, gloveAsset, gloveModel) =>
          isGenericWeltingAvailable(glove, gloveAsset) &&
          !!leather &&
          leather.limitations.weltingTypes.back &&
          !glove.limitations.isWeltingBackDisabled &&
          gloveModel.hasWeltingBack,
        visibleOptions: getLeatherColorSubset('welting'),
        options: LEATHER_COLORS,
      }),

      handSewn: Select({
        ...boilerplate(PROP_DEF_DICT.colors_welting_handSewn),
        dependencies: [
          'product.glove',
          'product.leather',
          'env.currency',
          'env.vendorId',
        ],
        isAvailable: (glove, leather, _currency, vendorId) =>
          !!glove &&
          glove.asset.hswGloveAssetId &&
          !!leather &&
          leather.limitations.hsw &&
          vendorId === 'rawlings',
        options: (_glove, _leather, currency) =>
          _.map(TOGGLES, (toggle) =>
            toggle.id === 'yes' ?
              {
                ...toggle,
                description: `+${currency.props.prefix}${HSW_EXTRA_PRICE}`,
              }
            : toggle,
          ),
      }),

      handSewnStitching: Select({
        ...boilerplate(PROP_DEF_DICT.colors_welting_handSewnStitching),
        dependencies: ['product.leather', 'colors.welting.handSewn'],
        isAvailable: (_leather, handSewn) =>
          !!handSewn && handSewn.id === 'yes',
        visibleOptions: getLeatherColorSubset('handSewnStitching'),
        options: LEATHER_COLORS,
      }),
    },

    binding: Select({
      ...boilerplate(PROP_DEF_DICT.colors_binding),
      dependencies: ['product.leather'],
      isAvailable: (leather) => !!leather,
      visibleOptions: getLeatherColorSubset('binding'),
      options: LEATHER_COLORS,
    }),

    trim: {
      style: Select({
        ...boilerplate(PROP_DEF_DICT.colors_trim_style),
        dependencies: [
          'product.glove',
          'calc.gloveAsset',
          'colors.web.style',
          'colors.shellBack.material',
          'colors.shellBack.design',
        ],
        isAvailable: (glove) => !!glove,
        autoUnavailable: true,
        visibleOptions: (
          glove,
          gloveAsset,
          web,
          shellBackMaterial,
          shellBackDesign,
        ) => {
          let trimIds = glove.limitations.trims

          const { trimWithShellBack } = gloveAsset.props
          const trimOptionsWithShellBack =
            glove.limitations.trimOptionsWithShellBack

          if (
            trimWithShellBack &&
            trimOptionsWithShellBack?.[shellBackDesign.id]
          ) {
            trimIds = trimOptionsWithShellBack[shellBackDesign.id]
          }

          if (web?.id === 'Trapeze' && gloveAsset.id !== '6019FS') {
            // Only single trim option for Trapeze web.
            // If Trapeze web enabled shellPalm has ben replaced by shellback web view for 3039FS, so there is no edge between shellPalm and shellBack.
            trimIds = ['1', '6', '7']
          }

          if (shellBackMaterial && !trimWithShellBack) {
            // Only single trim option when shellBack material is available and trimWithShellBack is set to FALSE.
            trimIds = ['1']
          }

          return _.map(trimIds, (trimId) => TRIM_DICT[trimId])
        },
        options: TRIMS,
      }),

      color: Select({
        ...boilerplate(PROP_DEF_DICT.colors_trim_color),
        dependencies: ['product.leather', 'colors.trim.style'],
        isAvailable: (leather, trim) => !!leather && !!trim,
        visibleOptions: getLeatherColorSubset('trim'),
        options: LEATHER_COLORS,
      }),
    },

    stitching: Select({
      ...boilerplate(PROP_DEF_DICT.colors_stitching),
      dependencies: ['product.leather'],
      isAvailable: (leather) => !!leather,
      visibleOptions: getLeatherColorSubset('thread'),
      options: LEATHER_COLORS,
    }),

    stamping: Select({
      ...boilerplate(PROP_DEF_DICT.colors_stamping),
      dependencies: ['product.leather'],
      isAvailable: (leather) => !!leather,
      options: STAMP_COLORS,
      visibleOptions: (leather) =>
        _.filter(
          STAMP_COLORS,
          (stampColor) => stampColor.subsets.leatherId[leather.id],
        ),
    }),
  },

  options: {
    fingerPadHood: {
      fingerPad: Select({
        ...boilerplate(PROP_DEF_DICT.options_fingerPadHood_fingerPad),
        dependencies: ['calc.gloveAsset', 'product.glove', 'product.leather'],
        isAvailable: (_, glove) =>
          !!(
            glove &&
            (glove.limitations.fingerIds.index ||
              glove.limitations.fingerIds.middle)
          ),
        isRequired: (gloveAsset) =>
          !!(gloveAsset && gloveAsset.props.fingerPadIsRequired),
        options: FINGER_COLORS,
        visibleOptions: (_, __, leather) =>
          FINGER_COLORS.filter(
            (fingerColor) => fingerColor.subsets.leatherId[leather.id],
          ),
      }),

      fingerHood: Select({
        ...boilerplate(PROP_DEF_DICT.options_fingerPadHood_fingerHood),
        dependencies: ['product.glove'],
        isAvailable: (glove) => !!(glove && glove.limitations.fingerHood),
        options: TOGGLES,
      }),

      position: Select({
        ...boilerplate(PROP_DEF_DICT.options_fingerPadHood_position),
        dependencies: [
          'product.glove',
          'options.fingerPadHood.fingerPad',
          'options.fingerPadHood.fingerHood',
        ],
        isAvailable: (_, fingerPad, fingerHood) =>
          !!fingerPad || (!!fingerHood && fingerHood.id === 'yes'),
        visibleOptions: (glove) =>
          _.filter(
            FINGERS,
            (finger) => glove && glove.limitations.fingerIds[finger.id],
          ),
        options: FINGERS,
      }),
    },

    palmPad: Select({
      ...boilerplate(PROP_DEF_DICT.options_palmPad),
      dependencies: [
        'product.leather',
        'calc.gloveAsset',
        'calc.gloveModel',
        'env.vendor',
      ],
      isAvailable: (leather, gloveAsset, gloveModel, vendor) => {
        if (vendor?.features.is3d) {
          return !!leather && !!gloveAsset && gloveModel?.hasPalmPadLeather
        }
        return !!leather && !!gloveAsset
      },
      visibleOptions: (leather, gloveAsset) =>
        _.filter(
          PALM_PADS,
          (palmPad) =>
            palmPad.subsets.leatherId[leather.id] &&
            (palmPad.id === 'xrd' ? gloveAsset.props.xrdPad : true),
        ),
      options: PALM_PADS,
    }),

    heelPad: Select({
      ...boilerplate(PROP_DEF_DICT.options_heelPad),
      dependencies: ['product.leather', 'calc.gloveAsset'],
      options: HEEL_PADS,
      isAvailable: (_, gloveAsset) =>
        !!gloveAsset && gloveAsset.props.hasHeelPad,
      visibleOptions: (leather, gloveAsset) => {
        if (
          (leather && leather.props.forceFullHeel) ||
          (gloveAsset && gloveAsset.props.forceFullHeel)
        ) {
          return [HEEL_PAD_DICT.full]
        }
        if (gloveAsset && gloveAsset.props.forceHalfHeel) {
          return [HEEL_PAD_DICT.half]
        }
        return HEEL_PADS
      },
    }),

    wristLining: Select({
      ...boilerplate(PROP_DEF_DICT.options_wristLining),
      dependencies: ['product.leather', 'calc.gloveAsset'],
      isAvailable: (_, gloveAsset) =>
        !!gloveAsset && gloveAsset.props.hasWristLining,
      visibleOptions: (leather) =>
        _.filter(
          WRIST_LININGS,
          (wristLining) => wristLining.subsets.leatherId[leather.id],
        ),
      options: WRIST_LININGS,
    }),

    sweatband: Select({
      ...boilerplate(PROP_DEF_DICT.options_sweatband),
      dependencies: ['calc.gloveAsset'],
      isAvailable: (gloveAsset) => !!gloveAsset && gloveAsset.props.sweatband,
      options: TOGGLES,
    }),

    breakIn: Select({
      ...boilerplate(PROP_DEF_DICT.options_breakIn),
      dependencies: ['product.leather', 'product.glove'],
      isAvailable: (leather, glove) =>
        !!leather && !!glove && glove.id !== 'FL12TR',
      visibleOptions: (leather) =>
        _.filter(BREAK_INS, (breakIn) => breakIn.subsets.leatherId[leather.id]),
      options: BREAK_INS,
    }),
  },

  personalization: {
    embroidery: {
      color: Select({
        ...boilerplate(PROP_DEF_DICT.personalization_embroidery_color),
        dependencies: ['product.leather'],
        isAvailable: (leather) => leather?.id !== 'rev1x',
        visibleOptions: _.filter(
          LEATHER_COLORS,
          (leatherColor) => leatherColor.subsets.common.embroidery,
        ),
        options: LEATHER_COLORS,
      }),

      number: {
        text: Text({
          ...boilerplate(PROP_DEF_DICT.personalization_embroidery_number_text),
          dependencies: ['calc.gloveAsset'],
          isAvailable: (gloveAsset) => isNumberAvailable(gloveAsset),
          maxLength: 2,
          pattern: /^[0-9]+$/,
        }),

        font: Select({
          ...boilerplate(PROP_DEF_DICT.personalization_embroidery_number_font),
          dependencies: ['calc.gloveAsset', 'product.leather'],
          isAvailable: (gloveAsset, leather) =>
            isNumberAvailable(gloveAsset) ||
            isThumbAvailable(leather) ||
            isPinkyAvailable(leather),
          options: FONTS,
        }),
      },

      logo: Select({
        ...boilerplate(PROP_DEF_DICT.personalization_embroidery_logo),
        dependencies: ['calc.sport', 'product.leather', 'env.vendor'],
        isAvailable: (_, leather, vendor) => {
          if (vendor.features.disableLogo) {
            return false
          }

          return !!leather && ['kip', 'hoh'].includes(leather.id)
        },
        options: LOGOS,
        visibleOptions: (sport) => {
          const availableCategories = LOGO_CATEGORIES.filter((category) =>
            sport ? category.sports.includes(sport?.id) : category,
          )
          const availableCategoryIds = availableCategories.map(({ id }) => id)

          return LOGOS.filter(
            (logo) =>
              !logo.isDisabled &&
              availableCategoryIds.includes(logo.categoryId),
          ).map((logo) => ({
            ...logo,
            category: availableCategories.find(
              ({ id }) => id === logo.categoryId,
            ),
          }))
        },
      }),

      thumb: {
        text: Text({
          ...boilerplate(PROP_DEF_DICT.personalization_embroidery_thumb_text),
          dependencies: ['product.leather'],
          isAvailable: (leather) => isThumbAvailable(leather),
          maxLength: 15,
          pattern: TEXT_PATTERN,
        }),

        font: Select({
          ...boilerplate(PROP_DEF_DICT.personalization_embroidery_thumb_font),
          dependencies: [
            'product.leather',
            'personalization.embroidery.number.font',
          ],
          isAvailable: (leather) => isThumbAvailable(leather),
          value: (_, font) => () => font.id,
          options: FONTS,
        }),
      },

      pinky: {
        text: Text({
          ...boilerplate(PROP_DEF_DICT.personalization_embroidery_pinky_text),
          dependencies: ['product.leather'],
          isAvailable: (leather) => isPinkyAvailable(leather),
          maxLength: 15,
          pattern: TEXT_PATTERN,
        }),

        font: Select({
          ...boilerplate(PROP_DEF_DICT.personalization_embroidery_pinky_font),
          dependencies: [
            'product.leather',
            'personalization.embroidery.number.font',
          ],
          isAvailable: (leather) => isPinkyAvailable(leather),
          value: (_leather, font) => () => font.id,
          options: FONTS,
        }),
      },

      pinkyPalm: {
        text: Text({
          ...boilerplate(
            PROP_DEF_DICT.personalization_embroidery_pinkyPalm_text,
          ),
          dependencies: ['calc.gloveAsset'],
          isAvailable: (gloveAsset) =>
            gloveAsset && gloveAsset.props.hasPinkyPalm,
          maxLength: 15,
          pattern: TEXT_PATTERN,
        }),
      },
    },

    flag: Select({
      ...boilerplate(PROP_DEF_DICT.personalization_flag),
      dependencies: ['colors.shellBack.design', 'calc.gloveAsset'],
      isAvailable: (shellBackDesign, gloveAsset) => {
        if (!gloveAsset) {
          return false
        }
        return shellBackDesign && shellBackDesign.id === 'pro' ?
            gloveAsset.props.hasFlagOnHalfMesh
          : gloveAsset.props.hasFlag
      },

      options: FLAGS,
    }),
  },

  legacy: {
    sku: Text({
      isPrivate: true,
      dependencies: ['product.glove', 'product.leather'],
      isAvailable: (glove, leather) => !!glove && !!leather,
      value: (glove, leather) => () =>
        PRICING_SCHEME_DICT[glove.props.pricingSchemeId].props.sku[leather.id],
    }),

    shellBackId: Text({
      isPrivate: true,
      dependencies: ['product.glove'],
      isAvailable: (glove) => !!glove,
      value: (glove) => () => glove.limitations.shellBackId,
    }),

    meshStyle: Text({
      isPrivate: true,
      dependencies: ['colors.shellBack.design', 'colors.shellBack.material'],
      isAvailable: (design) => !!design && design.id !== 'leather',
      value: (design, material) => () => {
        if (!design.legacyName) {
          return ''
        }
        return `${design.legacyName} ${material.name}`
      },
    }),

    indexFingerPad: Text({
      isPrivate: true,
      dependencies: [
        'options.fingerPadHood.position',
        'options.fingerPadHood.fingerPad',
      ],
      value: (finger, color) => () => {
        if (!finger || finger.id !== 'index' || !color) {
          return 'No'
        }
        return color.id
      },
    }),

    indexFingerHood: Text({
      isPrivate: true,
      dependencies: [
        'options.fingerPadHood.position',
        'options.fingerPadHood.fingerHood',
      ],
      value: (finger, hood) => () => {
        if (!finger || finger.id !== 'index' || !hood || hood.id !== 'yes') {
          return 'No'
        }
        return 'Yes'
      },
    }),

    middleFingerPad: Text({
      isPrivate: true,
      dependencies: [
        'options.fingerPadHood.position',
        'options.fingerPadHood.fingerPad',
      ],
      value: (finger, color) => () => {
        if (!finger || finger.id !== 'middle' || !color) {
          return 'No'
        }
        return color.id
      },
    }),

    middleFingerHood: Text({
      isPrivate: true,
      dependencies: [
        'options.fingerPadHood.position',
        'options.fingerPadHood.fingerHood',
      ],
      value: (finger, hood) => () => {
        if (!finger || finger.id !== 'middle' || !hood || hood.id !== 'yes') {
          return 'No'
        }
        return 'Yes'
      },
    }),
  },
})

export default controlTree

export const withCT = withProps({ controlTree })
