import { debounce } from '@orangelv/utils';
import { useEffect, useRef } from 'react';

import clearCanvas from './clear-canvas.js';
import digest from './digest.js';
import resizeScene from './resize-scene.js';
import { getState, setDigesting, setProps } from './state.js';
import type { Props, Ref, State } from './types.js';
import usePrevious from './use-previous.js';

const DIGEST_DEBOUNCE = 100;

const useRenderer = (stateRef: Ref<State>, props: Props): void => {
  const previousProps = usePrevious(props);

  const digestDebouncedRef = useRef(
    debounce(async (digestProps: Props, previousDigestProps?: Props) => {
      const { canvasRef, coverCanvasRef, isDigesting, isGeneratingPreviews } =
        getState(stateRef);

      if (!canvasRef.current || !coverCanvasRef.current) {
        return;
      }

      if (isGeneratingPreviews) {
        return;
      }

      if (isDigesting) {
        // If there's a digest already happening, don't digest the new props just yet.
        // Instead reschedule the digest to happen after digesting the current props.
        digestDebouncedRef.current(digestProps, previousDigestProps);
        return;
      }

      setDigesting(stateRef)(true);
      setProps(stateRef)(digestProps, previousDigestProps);
      await digest(stateRef);
      setDigesting(stateRef)(false);
    }, DIGEST_DEBOUNCE),
  );

  useEffect(() => {
    digestDebouncedRef.current(props, previousProps);
  }, [props, previousProps]);

  const { isHidden } = props;
  const resizeSceneRef = useRef(() => {
    resizeScene(stateRef, isHidden)();
  });

  useEffect(() => {
    const listener = resizeSceneRef.current;
    if ('window' in globalThis) {
      globalThis.window.addEventListener('resize', listener);
    }

    return (): void => {
      if ('window' in globalThis) {
        globalThis.window.removeEventListener('resize', listener);
      }
    };
  }, []);

  useEffect(() => {
    resizeScene(stateRef, isHidden)();
  }, [props.autoResize, isHidden, stateRef]);

  useEffect(() => {
    if (isHidden) {
      clearCanvas(stateRef)();
    }
  }, [isHidden, stateRef]);
};

export default useRenderer;
