import { LRUCache } from 'lru-cache';
import sizeof from 'sizeof';

import type { IncrementMap } from './increment-map.js';

export * from './color.js';
export * from './debounce.js';

// https://stackoverflow.com/a/65914848/242684
export type Tuple<T, N, R extends unknown[] = []> =
  R['length'] extends N ? R : Tuple<T, N, [...R, T]>;

export const isTupleOfLength = <V, L extends number>(
  tuple: readonly V[],
  length: L,
): tuple is Tuple<V, L> => tuple.length === length;

// https://github.com/microsoft/TypeScript/issues/29841
export const mapTuple = <T extends unknown[], R>(
  array: T,
  callback: (value: T[number], index: number, array: T) => R,
): { [K in keyof T]: R } =>
  array.map(
    // eslint-disable-next-line unicorn/no-array-callback-reference -- Getting args and passing them along adds too much boilerplate
    callback as (value: unknown, index: number, array: readonly unknown[]) => R,
  ) as { [K in keyof T]: R };

/**
 * Creates an object with the same keys as object and values generated by
 * running each own enumerable property of object through iteratee. The iteratee
 * function is invoked with three arguments: (value, key, object).
 *
 * @param object - The object to iterate over.
 * @param iteratee - The function invoked per iteration.
 * @returns Returns the new mapped object.
 */
export const mapValues = <T extends object, V>(
  object: T,
  iteratee: (value: T[keyof T], key: keyof T, object: T) => V,
): { [k in keyof T]: V } =>
  Object.fromEntries(
    Object.entries(object).map(([key, value]) => [
      key,
      iteratee(value as T[keyof T], key as keyof T, object),
    ]),
  ) as { [k in keyof T]: V };

export const isDefined = <T>(x: T | undefined | null): x is T =>
  x !== undefined && x !== null;

export const isDefinedArray = <T>(
  x: readonly (T | undefined | null)[],
): x is T[] => x.every((y) => y !== undefined && y !== null);

// https://github.com/microsoft/TypeScript/issues/13923
export type Immutable<T> =
  T extends string | number | boolean | null | undefined ? T
  : T extends (
    unknown[] // Gotta be pre-LengthAtLeast, hence the duplication
  ) ?
    { readonly [K in keyof T]: Immutable<T[K]> }
  : T extends LengthAtLeast<infer I, infer L> ?
    LengthAtLeast<readonly Immutable<I[number]>[], L>
  : { readonly [K in keyof T]: Immutable<T[K]> };

// https://github.com/microsoft/TypeScript/issues/24509#issuecomment-554536559
export type Mutable<T> = { -readonly [K in keyof T]: Mutable<T[K]> };

export type Replace<U, M, A> = U extends M ? A : U;

type Indices<L extends number, T extends number[] = []> =
  T['length'] extends L ? T[number] : Indices<L, [...T, T['length']]>;

export type LengthAtLeast<T extends ArrayLike<unknown>, L extends number> = T &
  Required<Pick<T, Indices<L>>>;

// https://stackoverflow.com/a/69370003/242684
export const hasLengthAtLeast = <
  T extends ArrayLike<unknown>,
  L extends number,
>(
  arrayLike: T,
  length: L,
): arrayLike is LengthAtLeast<T, L> => arrayLike.length >= length;

export const mapSameLengthAtLeast = <T, L extends number, R>(
  array: LengthAtLeast<readonly T[], L>,
  callback: (value: T, index: number, array: LengthAtLeast<T[], L>) => R,
): LengthAtLeast<R[], L> =>
  array.map(
    // eslint-disable-next-line unicorn/no-array-callback-reference -- Getting args and passing them along adds too much boilerplate
    callback as (value: T, index: number, array: readonly T[]) => R,
  ) as LengthAtLeast<R[], L>;

export type AllOrNothing<T> = T | { [K in keyof T]?: undefined };

export type PartialWithUndefined<T> = { [K in keyof T]?: T[K] | undefined };

/**
 * What's the difference from `PartialWithUndefined`? The properties may be
 * `undefined`, but they must always be present.
 */
export type PotentiallyUndefined<T> = { [K in keyof T]: T[K] | undefined };

// https://stackoverflow.com/a/73908282/242684

export type Incrementable = keyof IncrementMap;
export type Increment<N extends Incrementable> = IncrementMap[N];

type Range<
  F extends Incrementable,
  T extends Incrementable,
  A extends number[] = [],
> = F extends T ? A[number] | F : Range<Increment<F>, T, [...A, F]>;

export type RangeUnion<T extends readonly [Incrementable, Incrementable]> =
  T extends unknown ? Range<T[0], T[1]> : never;

export const sleep = async (durationMs: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, durationMs);
  });

export const until = async (
  predicate: () => boolean,
  intervalMs = 100,
): Promise<void> =>
  new Promise((resolve) => {
    const intervalId = setInterval(() => {
      if (predicate()) {
        clearInterval(intervalId);
        resolve();
      }
    }, intervalMs);
  });

export const hasDuplicates = (array: readonly unknown[]): boolean =>
  array.some((x, index, self) => self.indexOf(x) !== index);

export const getDuplicates = <T>(array: readonly T[]): T[] =>
  array.filter((x, index, self) => self.indexOf(x) !== index);

export const unique = <T>(array: readonly T[]): T[] =>
  array.filter((x, index, self) => self.indexOf(x) === index);

export function toNumber(string: string): number | undefined {
  if (/[^\d\s+.E-]/iu.test(string)) return undefined;
  const number = Number(string);
  if (!Number.isFinite(number)) return undefined;
  return number;
}

export function toNumberOrDie(string: string): number {
  const number = toNumber(string);
  if (number === undefined) throw new Error('Bad number');
  return number;
}

export * from './expr-eval.js';

export enum Severity {
  Error = 'error',
  Warning = 'warning',
}

export type BaseDiagnostic = {
  severity: Severity;
  message: string;
  extra?: unknown;
};

export function addBaseDiagnostic(
  diagnostics: BaseDiagnostic[],
  severity: Severity,
  message: string,
  extra?: unknown,
): void {
  diagnostics.push({ severity, message, extra });
}

/** The directory where the sources are in Blocks. */
export const SOURCE_DIR = 'src';
/** The directory where the build artifacts go in Blocks. */
export const BUILD_DIR = 'dist';

/**
 * Create a type-safe predicate for discriminated union filtering. Use like so:
 *
 * ```ts
 * const array = [
 *   { type: "foo", a: 1 },
 *   { type: "foo", a: 2 },
 *   { type: "bar", a: 1 },
 * ];
 *
 * array.filter(byType("foo", (x) => x.a === 1)); // [{ type: "foo", a: 1 }]
 * ```
 *
 * @remarks
 *
 * Direct usage of `is` is unsafe, as it's pretty much a cast, so you could
 * accidentally lie about the returned value:
 *
 * ```ts
 * const blah = <T>(x: T): x is Extract<T, { type: "bar" }> => x.type === "foo"
 * ```
 *
 * @see {@link https://github.com/microsoft/TypeScript/issues/16069}
 */
export const byType =
  <T extends string, V extends { type: string }>(
    type: T,
    predicate?: (value: V, index: number, array: readonly V[]) => boolean,
  ) =>
  (
    value: V,
    index: number,
    array: readonly V[],
  ): value is Extract<V, { type: T }> =>
    value.type === type && (predicate ? predicate(value, index, array) : true);

export type Memo = <T extends object | Promise<object>, P extends unknown[]>(
  f: (...parameters: P) => T,
  getCacheKey: (...parameters: P) => string,
) => typeof f;

/**
 * Shared cache memoization. That is, all functions memoized with this share
 * the same chunk of memory.
 */
export function createSharedMemo(): Memo {
  let namespaceCounter = 0;
  const cache = new LRUCache<string, object>({ maxSize: 10 ** 7 }); // 10 MB
  return (f, getCacheKey) => {
    const namespace = `${namespaceCounter}/`;
    namespaceCounter += 1;
    return (...parameters) => {
      const key = `${namespace}${getCacheKey(...parameters)}`;
      const cachedValue = cache.get(key);
      if (cachedValue) return cachedValue as ReturnType<typeof f>;
      const value = f(...parameters);
      cache.set(key, value, { size: 1 });

      void (async () => {
        const size = sizeof.sizeof(await value);
        if (size <= 1) return;
        if (cache.peek(key) !== value) return;
        cache.delete(key);
        cache.set(key, value, { size });
      })();

      return value;
    };
  };
}

/**
 * No-op memoization to be used in tests in place of other `Memo`.
 *
 * ```ts
 * const noopMemo = (f) => f;
 * ```
 */
export const noopMemo: Memo = (f) => f;

export const clamp = (n: number, min: number, max: number): number =>
  Math.min(Math.max(n, min), max);

/** Tests if `value` is truthy, and throws if it isn't. */
export function ok(
  value: unknown,
  message?: string,
  errorOptions?: { cause?: unknown },
): asserts value {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- We really don't care about the value type
  if (!value) throw new Error(message ?? 'Assertion failed!', errorOptions);
}

export const MAX_UNSIGNED_INT_32 = 2 ** 32 - 1;
export const MAX_INT_32 = 2 ** 31 - 1;

export const toSigned32 = (x: number): number =>
  x <= MAX_INT_32 ? x : x - MAX_UNSIGNED_INT_32 - 1;

export const toUnsigned32 = (x: number): number =>
  x >= 0 ? x : x + MAX_UNSIGNED_INT_32 + 1;

export const MAX_UNSIGNED_INT_64 = 2n ** 64n - 1n;
export const MAX_INT_64 = 2n ** 63n - 1n;

export const toSigned64 = (x: bigint): bigint =>
  x <= MAX_INT_64 ? x : x - MAX_UNSIGNED_INT_64 - 1n;

export const toUnsigned64 = (x: bigint): bigint =>
  x >= 0 ? x : x + MAX_UNSIGNED_INT_64 + 1n;
