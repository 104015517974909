import { garmentSizes } from '~c/common/meta'

const getRosterEntries = (nodes) => {
  const rosterIds = [
    ...new Set(
      Object.keys(nodes)
        .filter((x) => x.startsWith('details.roster.'))
        .map((x) => {
          const match = x.match(/^details\.roster\.([^.]+)/)
          return match ? match[1] : null
        })
        .filter(Boolean),
    ),
  ]

  return rosterIds.map((rosterId) => {
    const extrasKeys = Object.keys(nodes).filter((key) =>
      key.startsWith(`details.roster.${rosterId}.extras.`),
    )

    const extras = Object.fromEntries(
      extrasKeys.map((key) => {
        const extraKey = key.split(`details.roster.${rosterId}.extras.`)[1]
        return [extraKey, nodes[key].value]
      }),
    )

    const sizes = Object.fromEntries(
      garmentSizes.map(({ path }) => [
        path,
        nodes[`details.roster.${rosterId}.${path}`].value,
      ]),
    )

    return {
      id: rosterId,
      ...sizes,
      quantity: nodes[`details.roster.${rosterId}.quantity`].value,
      number: nodes[`details.roster.${rosterId}.number`].value,
      name: nodes[`details.roster.${rosterId}.name`].value,
      ...(Object.keys(extras).length > 0 && { extras }),
    }
  })
}

export const getRosterDetailsValue = (nodes) => {
  const rosterEntries = getRosterEntries(nodes)
  const hasYearData = rosterEntries.some((row) => row.extras?.year)
  const entries = rosterEntries.map((row) => {
    const garmentNames = garmentSizes
      .filter((garment) => row[garment.path])
      .map((garment) => garment.sheet[row[garment.path]].name)

    const baseRow = [
      ...garmentNames,
      row.quantity,
      row.number || '-',
      row.name || '-',
    ]

    return hasYearData ? [...baseRow, row.extras?.year || '-'] : baseRow
  })

  const garmentTitles = garmentSizes
    .filter((garment) => rosterEntries[0][garment.path])
    .map((garment) => garment.title)

  const headerRow = [...garmentTitles, 'Quantity', 'Number', 'Name']

  if (hasYearData) {
    headerRow.push('Year')
  }

  entries.unshift(headerRow)
  return entries
}

export const getSizeSummaryValue = (nodes) => {
  const rows = [['Size', 'Quantity']]
  const rosterEntries = getRosterEntries(nodes)

  const sizesCount = garmentSizes.filter((x) => rosterEntries[0][x.path]).length

  for (const garment of garmentSizes) {
    // Safe to assume that if the size is missing for one of the garments then
    // it means it wasn't enabled, otherwise it wouldn't be valid.
    if (!rosterEntries[0][garment.path]) continue

    const sizeQuantities = {}

    for (const entry of rosterEntries) {
      const sizeName = garment.sheet[entry[garment.path]].name
      sizeQuantities[sizeName] =
        (sizeQuantities[sizeName] || 0) + +entry.quantity
    }

    let garmentRows = Object.entries(sizeQuantities)

    if (garment.sheetArray) {
      garmentRows.sort(([sizeIdA], [sizeIdB]) => {
        const indexA = garment.sheetArray.findIndex(
          (item) => item.id === sizeIdA,
        )
        const indexB = garment.sheetArray.findIndex(
          (item) => item.id === sizeIdB,
        )
        return indexA - indexB
      })
    }

    rows.push(
      ...garmentRows.map(([sizeName, quantity]) => [
        sizesCount > 1 ? `${garment.title} ${sizeName}` : sizeName,
        quantity,
      ]),
    )
  }

  let total = 0
  for (const entry of rosterEntries) {
    total += +entry.quantity
  }

  rows.push([sizesCount > 1 ? 'Total sets' : 'Total', total])

  return rows
}
