import { isDefined, ok } from '@orangelv/utils';
import toposort from 'toposort';

import addPointer from './add-pointer.js';
import createCamera from './camera/create-camera.js';
import updateCamera from './camera/update-camera.js';
import createPipeline from './create-pipeline.js';
import createScene from './create-scene.js';
import loadModel from './load-model.js';
import updateMaterials from './materials/update-materials.js';
import runRenderLoop from './run-render-loop.js';
import scheduleLoading from './schedule-loading.js';
import { getState, setReady } from './state.js';
import { LogLevel, type Ref, type State } from './types.js';
import unloadModel from './unload-model.js';
import updateMeshes from './update-meshes.js';
import updatePositions from './update-positions.js';
import updateSkybox from './update-skybox.js';

const digest = async (stateRef: Ref<State>): Promise<void> => {
  const timeStart = performance.now();

  const { props } = getState(stateRef);

  if (props.onLogGroup) {
    props.onLogGroup(true);
  }

  if (props.onLog) {
    props.onLog(LogLevel.Info, 'Digest starts');
  }

  setReady(stateRef)(false);

  const finishLoading = scheduleLoading(stateRef)(timeStart);

  if (!getState(stateRef).scene) {
    await createScene(stateRef)(props.config);

    createCamera(stateRef)();

    createPipeline(stateRef)();

    addPointer(stateRef)();

    runRenderLoop(stateRef)();
  }

  const haveModelsChanged = await Promise.all(
    Object.entries(props.config.models).map(async ([modelId, model]) => {
      const modelState = getState(stateRef).models[modelId];

      const hasModelChanged = modelState?.url !== model?.url;
      if (hasModelChanged) {
        if (modelState) unloadModel(stateRef)(modelId);
        if (model) await loadModel(stateRef)(modelId);
      }

      return hasModelChanged;
    }),
  );

  const state = getState(stateRef);

  const isAutoRotateChanged =
    state.props.config.camera?.autoRotate !==
    state.previousProps?.config.camera?.autoRotate;

  if (
    haveModelsChanged.includes(true) ||
    state.isGeneratingPreviews ||
    isAutoRotateChanged
  ) {
    updatePositions(stateRef)();
    updateCamera(stateRef)();
  }

  await Promise.all([
    (async () => {
      const modelIdsInOrder = toposort(
        Object.entries(props.config.models).map(([modelId, model]) => [
          modelId,
          model?.dependsOn,
        ]),
      )
        .filter((modelId) => isDefined(modelId))
        .reverse();

      const modelIdGroups = new Map<string | undefined, string[]>();
      for (const modelId of modelIdsInOrder) {
        const { dependsOn } = props.config.models[modelId] ?? {};
        const group = modelIdGroups.get(dependsOn) ?? [];
        group.push(modelId);
        modelIdGroups.set(dependsOn, group);
      }

      for (const [, modelIdsInGroup] of modelIdGroups) {
        // eslint-disable-next-line no-await-in-loop -- We want to wait for the group to finish before continuing with the next group
        await Promise.all(
          modelIdsInGroup.map(async (modelId) => {
            updateMeshes(stateRef)(modelId, props.config);
            await updateMaterials(stateRef)(modelId, props.config);
          }),
        );
      }
    })(),
    (async () => {
      const isSkyboxChanged =
        JSON.stringify(state.props.config.scene?.skyboxTexture) !==
        JSON.stringify(state.previousProps?.config.scene?.skyboxTexture);

      if (isSkyboxChanged) {
        await updateSkybox(stateRef)(state.props.config.scene);
      }
    })(),
  ]);

  globalThis.bjsRenderer ??= {};
  globalThis.bjsRenderer.state = state;

  const { scene } = state;

  ok(scene);

  if (finishLoading) await finishLoading();

  if (!props.isHidden) {
    scene.render();
  }

  if (Object.values(props.config.models).some(Boolean)) {
    setReady(stateRef)(true);
  }

  if (props.onLog) {
    const duration = performance.now() - timeStart;

    props.onLog(LogLevel.Info, `Digest ended in ${duration}ms`);
  }

  if (props.onLogGroup) {
    props.onLogGroup(false);
  }
};

export default digest;
