// DO NOT EDIT, GENERATED BY "yarn sheets"

import { t } from '../../../../platform/i18n'

import type { LeatherColorRow } from '../typings';

const data = [
  {
    "id": "toneOnTone",
    "props": {
      "hex": "#a9b6bc",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.toneOnTone.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.toneOnTone.description')
  },
  {
    "id": "metallicGold",
    "props": {
      "hex": "#ceb275",
      "isMetallic": true,
      "tileImageSrc": "icons/metallicColor/metallicGold.png"
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.metallicGold.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.metallicGold.description')
  },
  {
    "id": "metallicRoseGold",
    "props": {
      "hex": "#cd9b7a",
      "isMetallic": true,
      "tileImageSrc": "icons/metallicColor/metallicRoseGold.png"
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.metallicRoseGold.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.metallicRoseGold.description')
  },
  {
    "id": "metallicSilver",
    "props": {
      "hex": "#aeadad",
      "isMetallic": true,
      "tileImageSrc": "icons/metallicColor/metallicSilver.png"
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.metallicSilver.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.metallicSilver.description')
  },
  {
    "id": "metallicRoyal",
    "props": {
      "hex": "#02349D",
      "isMetallic": true,
      "tileImageSrc": "icons/metallicColor/metallicRoyal.png"
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.metallicRoyal.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.metallicRoyal.description')
  },
  {
    "id": "metallicScarlet",
    "props": {
      "hex": "#9E121A",
      "isMetallic": true,
      "tileImageSrc": "icons/metallicColor/metallicScarlet.png"
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.metallicScarlet.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.metallicScarlet.description')
  },
  {
    "id": "blackDry",
    "props": {
      "hex": "#242120",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.blackDry.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.blackDry.description')
  },
  {
    "id": "blackOiled",
    "props": {
      "hex": "#191818",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.blackOiled.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.blackOiled.description')
  },
  {
    "id": "blackSoftDualCore",
    "props": {
      "hex": "#141413",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.blackSoftDualCore.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.blackSoftDualCore.description')
  },
  {
    "id": "black",
    "props": {
      "hex": "#1f1c1c",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": true,
        "embroidery": true
      },
      "kip": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": true,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": true,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": true,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": true,
        "welting": true,
        "binding": true
      },
      "rla": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": true,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.black.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.black.description')
  },
  {
    "id": "purple",
    "props": {
      "hex": "#4a2366",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.purple.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.purple.description')
  },
  {
    "id": "militaryGreen",
    "props": {
      "hex": "#485637",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.militaryGreen.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.militaryGreen.description')
  },
  {
    "id": "teal",
    "props": {
      "hex": "#0f7a6e",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.teal.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.teal.description')
  },
  {
    "id": "vibrantRoyal",
    "props": {
      "hex": "#1841b1",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.vibrantRoyal.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.vibrantRoyal.description')
  },
  {
    "id": "camel",
    "props": {
      "hex": "#cda96b",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": false,
        "binding": true
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.camel.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.camel.description')
  },
  {
    "id": "darkGreen",
    "props": {
      "hex": "#2a3e32",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": true
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.darkGreen.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.darkGreen.description')
  },
  {
    "id": "gray",
    "props": {
      "hex": "#75716c",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.gray.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.gray.description')
  },
  {
    "id": "libertyGray",
    "props": {
      "hex": "#888887",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.libertyGray.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.libertyGray.description')
  },
  {
    "id": "navy",
    "props": {
      "hex": "#1b1c1e",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": false,
        "binding": true
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.navy.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.navy.description')
  },
  {
    "id": "orange",
    "props": {
      "hex": "#cf5608",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": true
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.orange.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.orange.description')
  },
  {
    "id": "royal",
    "props": {
      "hex": "#1b2c59",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": false,
        "binding": true
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.royal.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.royal.description')
  },
  {
    "id": "scarlet",
    "props": {
      "hex": "#9e1714",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": false,
        "binding": true
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.scarlet.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.scarlet.description')
  },
  {
    "id": "tan",
    "props": {
      "hex": "#a95313",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": true
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.tan.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.tan.description')
  },
  {
    "id": "timberglaze",
    "props": {
      "hex": "#7e4019",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": true
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.timberglaze.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.timberglaze.description')
  },
  {
    "id": "chocolate",
    "props": {
      "hex": "#31201a",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": true
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.chocolate.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.chocolate.description')
  },
  {
    "id": "goldTan",
    "props": {
      "hex": "#d07605",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.goldTan.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.goldTan.description')
  },
  {
    "id": "primoSherry",
    "props": {
      "hex": "#542825",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": true
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.primoSherry.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.primoSherry.description')
  },
  {
    "id": "columbiaBlue",
    "props": {
      "hex": "#5c8fb4",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": true
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.columbiaBlue.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.columbiaBlue.description')
  },
  {
    "id": "sandlot",
    "props": {
      "hex": "#35241f",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.sandlot.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.sandlot.description')
  },
  {
    "id": "pink",
    "props": {
      "hex": "#ce7c85",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": true
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.pink.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.pink.description')
  },
  {
    "id": "white",
    "props": {
      "hex": "#c9c6bb",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": true,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": false,
        "binding": true
      },
      "rla": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.white.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.white.description')
  },
  {
    "id": "jtDarkShadow",
    "props": {
      "hex": "#29241d",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": true
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.jtDarkShadow.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.jtDarkShadow.description')
  },
  {
    "id": "butterscotch",
    "props": {
      "hex": "#ab6919",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.butterscotch.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.butterscotch.description')
  },
  {
    "id": "goldenBrown",
    "props": {
      "hex": "#78451b",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": true
      },
      "rla": {
        "shell": false,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.goldenBrown.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.goldenBrown.description')
  },
  {
    "id": "darkTan",
    "props": {
      "hex": "#572617",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": true,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": true,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.darkTan.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.darkTan.description')
  },
  {
    "id": "graphite",
    "props": {
      "hex": "#3a3a3e",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.graphite.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.graphite.description')
  },
  {
    "id": "cork",
    "props": {
      "hex": "#8f654a",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.cork.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.cork.description')
  },
  {
    "id": "mochaDarkBrown",
    "props": {
      "hex": "#231d1c",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.mochaDarkBrown.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.mochaDarkBrown.description')
  },
  {
    "id": "darkBrown",
    "props": {
      "hex": "#231d1c",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.darkBrown.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.darkBrown.description')
  },
  {
    "id": "redOrange",
    "props": {
      "hex": "#c12517",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.redOrange.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.redOrange.description')
  },
  {
    "id": "brownBruciato",
    "props": {
      "hex": "#6c2918",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.brownBruciato.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.brownBruciato.description')
  },
  {
    "id": "richTan",
    "props": {
      "hex": "#ac5313",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.richTan.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.richTan.description')
  },
  {
    "id": "brown",
    "props": {
      "hex": "#53281d",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.brown.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.brown.description')
  },
  {
    "id": "maroon",
    "props": {
      "hex": "#533131",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.maroon.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.maroon.description')
  },
  {
    "id": "chocolateBrown",
    "props": {
      "hex": "#534139",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.chocolateBrown.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.chocolateBrown.description')
  },
  {
    "id": "yellow",
    "props": {
      "hex": "#d9cb17",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.yellow.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.yellow.description')
  },
  {
    "id": "primoBurgundy",
    "props": {
      "hex": "#302423",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.primoBurgundy.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.primoBurgundy.description')
  },
  {
    "id": "beige",
    "props": {
      "hex": "#b09362",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": true,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.beige.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.beige.description')
  },
  {
    "id": "copper",
    "props": {
      "hex": "#783e0e",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.copper.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.copper.description')
  },
  {
    "id": "gold",
    "props": {
      "hex": "#806826",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.gold.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.gold.description')
  },
  {
    "id": "ivory",
    "props": {
      "hex": "#c6bea1",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.ivory.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.ivory.description')
  },
  {
    "id": "opticYellow",
    "props": {
      "hex": "#d4ed19",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.opticYellow.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.opticYellow.description')
  },
  {
    "id": "primoBurgundyThread",
    "props": {
      "hex": "#300f12",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.primoBurgundyThread.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.primoBurgundyThread.description')
  },
  {
    "id": "silver",
    "props": {
      "hex": "#919191",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.silver.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.silver.description')
  },
  {
    "id": "vegasGold",
    "props": {
      "hex": "#b28e24",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.vegasGold.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.vegasGold.description')
  },
  {
    "id": "mint",
    "props": {
      "hex": "#92b599",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.mint.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.mint.description')
  },
  {
    "id": "oceanMint",
    "props": {
      "hex": "#92b599",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.oceanMint.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.oceanMint.description')
  },
  {
    "id": "coral",
    "props": {
      "hex": "#e58f78",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": true
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.coral.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.coral.description')
  },
  {
    "id": "splitBlack",
    "props": {
      "hex": "#141413",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.splitBlack.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.splitBlack.description')
  },
  {
    "id": "splitGray",
    "props": {
      "hex": "#75716c",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.splitGray.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.splitGray.description')
  },
  {
    "id": "mustard",
    "props": {
      "hex": "#a68300",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": true,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.mustard.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.mustard.description')
  },
  {
    "id": "red",
    "props": {
      "hex": "#ab060d",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": true,
        "embroidery": false
      },
      "kip": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "hoh": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rev1x": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "gxle": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      },
      "rla": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    },
    "name": t('rawlingsGloves:sheets.leatherColors.red.name'),
    "description": t('rawlingsGloves:sheets.leatherColors.red.description')
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<LeatherColorRow[]>> = data;

export type LeatherColor = typeof typed[number];

export const LEATHER_COLOR_INDEX_KEY = "id";
export type LeatherColorIndexKey = "id";
export type LeatherColorId = LeatherColor["id"];

let i = 0;
export const LEATHER_COLOR_DICT = {
  "toneOnTone": typed[i++],
  "metallicGold": typed[i++],
  "metallicRoseGold": typed[i++],
  "metallicSilver": typed[i++],
  "metallicRoyal": typed[i++],
  "metallicScarlet": typed[i++],
  "blackDry": typed[i++],
  "blackOiled": typed[i++],
  "blackSoftDualCore": typed[i++],
  "black": typed[i++],
  "purple": typed[i++],
  "militaryGreen": typed[i++],
  "teal": typed[i++],
  "vibrantRoyal": typed[i++],
  "camel": typed[i++],
  "darkGreen": typed[i++],
  "gray": typed[i++],
  "libertyGray": typed[i++],
  "navy": typed[i++],
  "orange": typed[i++],
  "royal": typed[i++],
  "scarlet": typed[i++],
  "tan": typed[i++],
  "timberglaze": typed[i++],
  "chocolate": typed[i++],
  "goldTan": typed[i++],
  "primoSherry": typed[i++],
  "columbiaBlue": typed[i++],
  "sandlot": typed[i++],
  "pink": typed[i++],
  "white": typed[i++],
  "jtDarkShadow": typed[i++],
  "butterscotch": typed[i++],
  "goldenBrown": typed[i++],
  "darkTan": typed[i++],
  "graphite": typed[i++],
  "cork": typed[i++],
  "mochaDarkBrown": typed[i++],
  "darkBrown": typed[i++],
  "redOrange": typed[i++],
  "brownBruciato": typed[i++],
  "richTan": typed[i++],
  "brown": typed[i++],
  "maroon": typed[i++],
  "chocolateBrown": typed[i++],
  "yellow": typed[i++],
  "primoBurgundy": typed[i++],
  "beige": typed[i++],
  "copper": typed[i++],
  "gold": typed[i++],
  "ivory": typed[i++],
  "opticYellow": typed[i++],
  "primoBurgundyThread": typed[i++],
  "silver": typed[i++],
  "vegasGold": typed[i++],
  "mint": typed[i++],
  "oceanMint": typed[i++],
  "coral": typed[i++],
  "splitBlack": typed[i++],
  "splitGray": typed[i++],
  "mustard": typed[i++],
  "red": typed[i++]
} as const;

export { typed as LEATHER_COLORS };
