import { Matrix } from '@babylonjs/core';

import { getState } from '../state.js';
import type { Ref, State } from '../types.js';

const getTranslationValue = (
  value: number | undefined,
  denominator: number,
): number =>
  value === undefined ? 0
  : Number.isInteger(value) ? value / denominator
  : value;

const updateCameraProjectionMatrix = (stateRef: Ref<State>): void => {
  const { scene, props } = getState(stateRef);
  if (!scene) throw new Error('Logic error');
  if (!scene.activeCamera) return;

  const { x, y } = props.config.camera?.translateProjection ?? {};
  if (x === undefined && y === undefined) return;

  const engine = scene.getEngine();
  const width = engine.getRenderWidth();
  const height = engine.getRenderHeight();

  // Force recompute as to avoid multiplying the matrix more than once
  const projectionMatrix = scene.activeCamera.getProjectionMatrix(true);
  projectionMatrix.multiplyToRef(
    Matrix.Translation(
      getTranslationValue(x, width),
      getTranslationValue(y, height),
      0,
    ),
    projectionMatrix,
  );
};

export default updateCameraProjectionMatrix;
