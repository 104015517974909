import { sleep } from '@orangelv/utils';

import { getState } from './state.js';
import type { Ref, State } from './types.js';

const LOADING_THRESHOLD = 100;
const LOADING_DURATION = 1000;

// Calls props.onLoading at appropriate time.
// If at least LOADING_THRESHOLD ms has passed, notify that we are loading. This means that fast updates won't trigger loading.
// When update has finished and it has triggered loading, sleep a bit so that we are loading for at least LOADING_DURATION ms.
const scheduleLoading =
  (stateRef: Ref<State>) =>
  (timeStart: number): (() => Promise<void>) | undefined => {
    const { props, previousProps, isGeneratingPreviews } = getState(stateRef);

    if (isGeneratingPreviews) return;

    const wasHidden = previousProps?.isHidden && !props.isHidden;

    let loadingTimeout = 0;

    const { onLoading } = props;
    if (onLoading) {
      loadingTimeout = globalThis.window.setTimeout(() => {
        onLoading(true);
      }, LOADING_THRESHOLD);
    }

    return async function finishLoading(): Promise<void> {
      if (!onLoading) return;

      // Don't clear loading timeout which will result in loading when hidden.
      if (props.isHidden) return;

      clearTimeout(loadingTimeout);

      const duration = performance.now() - timeStart;

      if (duration > LOADING_THRESHOLD || wasHidden) {
        const sleepDuration = LOADING_DURATION - duration;
        if (sleepDuration > 0) {
          await sleep(sleepDuration);
        }
      }

      onLoading(false);
    };
  };

export default scheduleLoading;
